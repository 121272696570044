:root {
  /* Light theme colors */
  --background-light: #f8f9fa;
  --text-light: #333;
  --card-bg-light: rgba(255, 255, 255, 0.9);
  --border-light: rgba(0, 0, 0, 0.1);
  --shadow-light: rgba(0, 0, 0, 0.1);
  --accent-light: #0ca678;
  --secondary-light: #96f2d7;
  
  /* Dark theme colors */
  --background-dark: #121212;
  --text-dark: #e0e0e0;
  --card-bg-dark: rgba(25, 25, 25, 0.8);
  --border-dark: rgba(255, 255, 255, 0.1);
  --shadow-dark: rgba(0, 0, 0, 0.4);
  --accent-dark: #4cc9f0;
  --secondary-dark: #12b886;
}

/* Add these global transition styles */
* {
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease;
}

/* Update the theme classes */
body.light-theme {
  background-color: var(--background-light);
  color: var(--text-light);
  transition: background-color 0.5s ease;
}

body.dark-theme {
  background-color: var(--background-dark);
  color: var(--text-dark);
  transition: background-color 0.5s ease;
}

body {
  background-color: black;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Add responsive font sizes */
@media (max-width: 768px) {
  h1 {
    font-size: 1.8rem !important;
  }
  h2 {
    font-size: 1.5rem !important;
  }
  h3 {
    font-size: 1.2rem !important;
  }
  p, .mantine-Text-root {
    font-size: 0.95rem !important;
  }
}

/* Improve focus visibility for accessibility */
:focus {
  outline: 2px solid var(--accent-dark) !important;
  outline-offset: 2px !important;
}

body.light-theme :focus {
  outline: 2px solid #0ca678 !important;
}

/* Improve text selection visibility */
::selection {
  background-color: var(--accent-dark);
  color: white;
}

body.light-theme ::selection {
  background-color: #0ca678;
  color: white;
}

/* Improve scrollbar visibility */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

body.dark-theme ::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.05);
}

body.dark-theme ::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.2);
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.3);
}

body.dark-theme ::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.3);
}
