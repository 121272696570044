@keyframes blink {
    0% {
      color: transparent;
    }
    50%{
    color: white;
    }
    100% { color:transparent}
  }

.blinkingCursor{
    animation-name: blink;
    animation-duration: 0.65s;
    animation-iteration-count: infinite;
}

.typewriter-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.typewriter-text {
  font-size: 3rem;
  font-weight: bold;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  z-index: 2;
  text-align: center;
}

.typewriter-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(0.6);
  z-index: 1;
}

/* Add this for light theme */
body.light-theme .typewriter-image {
  filter: brightness(0.7) contrast(1.1);
}

body.light-theme .typewriter-text {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}

  